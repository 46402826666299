<template>
	<div class="purchase">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/server/server' }">业务中心</el-breadcrumb-item>
			<el-breadcrumb-item>购买</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="setMeal">
			<div class="topType">
				<span class="text">套餐类型</span>
			</div>
			<div class="selectPool">
				<div class="text">选择矿池:</div>
				<el-select v-model="form.poolId" placeholder="请选择" @change="changePool">
					<el-option v-for="item in columns" :key="item.pool_id" :label="item.text" :value="item.pool_id">
					</el-option>
				</el-select>
			</div>
			<div class="packageContent">
				<div class="package" v-for="(item,index) in PPSpackage">
					<div class="left">
						<div class="price">单价:￥{{item.ppsPrice}}</div>
						<div class="number">pps数量:{{item.ppsNumber}}</div>
					</div>
					<div class="right">
						<!-- <van-stepper v-model="item.ppsBuyNumber" :default-value="0" min="0" @change="changeNum(item)"/> -->
						<div class="price">年限:{{item.ppsValidite}}</div>
						<div class="number">已购:{{item.ppsBuyNumber}}</div>
					</div>
				</div>
			</div>
			<div class="packageNum">
				<div class="packageNumText">pps数量:</div>
				<el-input-number v-model="ppsBuyNumber" @change="changeNum(ppsBuyNumber)" :min="0"  label="描述文字"></el-input-number>
			</div>
			<div class="totalMoney">
				<div class="text">合计:</div>
				<div class="number">{{amount}}</div>
			</div>
			<div class="purchaseBtn">
				<el-button type="primary" :disabled='isDisabled' @click="submitForm">提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Buy
	} from "@/api/index";
	export default {
		data() {
			return {
				value:'',
				isDisabled: false,
				pool_title: '',
				showPicker: false,
				use_package: 0,
				columns: [],
				form: {
					poolId:'1',
					ppsId: ''
				},
				total: {
					ppsbuynum: 0,
					ppsprice: 0
				},
				PPSpackage: [],
				kuangform: [],
				mechineform: [],
				mechine: {},
				sum: [],
				totalPrice: 0,
				commitForm:[],
				ppsBuyNumber:0,
				newArr:[],
				amount:0,
			}
		},
		created() {
			this.PPSlist();
			this.Machinelist();
		},
		methods:{
			//选择矿池
			changePool(val) {
				this.form.poolId = val;
			},
			//套餐计算
			changeNum(val) {
				let that = this;
				let total = val;
				let currentValue = 0;
				let amount = 0;
				that.newArr.forEach(function(item){
					currentValue = Math.floor(total / item.ppsNumber);
					item.ppsBuyNumber = currentValue 
					total -= currentValue * item.ppsNumber;
					amount += currentValue * item.ppsPrice;
				})
				that.amount = amount;
			},
			//pps列表
			async PPSlist() {
				let {
					status,
					data
				} = await Buy.ppslist({
					pool: this.form.poolId
				})
				if (status) {
					let that = this
					data.data.list.forEach(function(item, index) {
						that.PPSpackage.push({
							id: item.id,
							poolId: item.poolId,
							ppsName: item.ppsName,
							ppsNumber: item.ppsNumber,
							ppsPrice: item.ppsPrice,
							ppsValidite: item.ppsValidite,
							ppsValiditeDays: item.ppsValiditeDays,
							deliveryDays: item.deliveryDays,
							ppsBuyNumber: 0
						})
					})
					that.newArr = that.PPSpackage.reverse()
				}
			},
			//kangchi列表
			async Machinelist() {
				let {
					status,
					data
				} = await Buy.machinelist();
				if (status) {
					let that = this
					this.kuangform = data.data;
					this.kuangform.list.forEach(function(item, index) {
						if (index == 0) {
							that.pool_title = item.poolName;
							that.form.poolId = item.id;
						}
						let column = {
							text: item.poolName,
							pool_id: item.id,
							index: index
						}
						that.columns.push(column)
					})
				}
			},
			//选择矿池
			onConfirm(value) {
				this.pool_title = value.text;
				this.form.poolId = value.pool_id;
				this.showPicker = false;
			},
			//提交
			async submitForm() {
				if (this.form.poolId == '') {
					this.$message.error('请选择狂矿池')
					return
				}
				if (this.amount <= 0) {
					this.$message.error('金额需大于0')
					return
				}
				
				let {
					status,
					data
				} = await Buy.buy({
					poolId:this.form.poolId,
					ppsOrder:this.ppsBuyNumber,
					ppsAmount:this.amount
				});
				if (status) {
					if (data.code == 0) {
						this.goPay(data.data.orderId)
						this.isDisabled = true;
					} else {
						this.$message.error(data.message);
					}
				} else {
					this.$message.error('请求失败');
				}
			},
			goPay(id) {
				this.$router.push({
					path: "/server/second",
					query: {
						id: id,
					}
				})
			}
		}
	}
</script>

<style lang="less">
	.purchase {
		body {
			margin: 0;
			padding: 0;
			background-color: #F0F3FA;
		}
		.el-breadcrumb {
			padding: 30px;
			.el-breadcrumb__inner a,
			.el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}
			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}
		.setMeal {
			margin: 0 20px;
			background-color: #FFFFFF;
			padding-bottom: 10px;
			.topType {
				background-color: #F8F8F8;
				padding: 9px 20px;
				.text {
					font-size: 18px;
					color: #333333;
					font-weight: 600;
					margin-left: 10px;
				}
			}
			.topType::before{
				content:"";
				display: inline-block;
				width: 3px;
				height: 16px;
				background: #1493FA;
				vertical-align: middle;
			}
			
			.packageContent{
				margin: 20px;
				border-top: 1px solid #eeeeee;
				border-left: 1px solid #eeeeee;
				border-right: 1px solid #eeeeee;
				.package {
					padding: 5px 20px;
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;
					border-bottom: 1px solid #eeeeee;
					.left {
						font-size: 18px;
						color: #999999;
						.number {
							padding-top: 20px;
						}
					}
					.right {
						font-size: 18px;
						color: #999999;
						.number {
							text-align: right;
							padding-top: 20px;
						}
					}
				}
			}
			.packageNum {
				display: flex;
				align-items: center;
				padding: 20px;
				.packageNumText {
					font-size: 18px;
					color: #999999;
					margin-right: 20px;
				}
			}
			.totalMoney {
				display: flex;
				align-items: center;
				padding: 20px;
				.text {
					font-size: 18px;
					color: #999999;
					margin-right: 20px;
				}
				.number {
					font-size: 18px;
					color: #999999;
				}
			}
			.purchaseBtn {
				text-align: center;
			}
			.selectPool {
				display: flex;
				align-items: center;
				padding: 20px 0 0 20px;
				.text {
					font-size: 18px;
					color: #999999;
					margin-right: 20px;
				}
			}
		}
	}
</style>
